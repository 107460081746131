import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "/constants/vp-sizes";
const REBRAND_BACKGROUND_COLOR = "#2e3248";
const REBRAND_HOVER_BACKGROUND_COLOR = "#262940";
export const StyledSelectableTagsGridComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.website-alignment-branding {
    .selectable-tag-wrapper.dark-mode {
      background-color: ${REBRAND_BACKGROUND_COLOR};
      border-radius: 4px;

      .selectable-tag-image-container {
        background-color: ${REBRAND_BACKGROUND_COLOR};

        .selectable-tag-image {
          path {
            fill: ${REBRAND_BACKGROUND_COLOR};
          }
        }
      }
    }

    .selectable-tag-wrapper.dark-mode:hover {
      background-color: ${REBRAND_HOVER_BACKGROUND_COLOR};

      .selectable-tag-image-container {
        background-color: ${REBRAND_HOVER_BACKGROUND_COLOR};

        .selectable-tag-image {
          path {
            fill: ${REBRAND_HOVER_BACKGROUND_COLOR};
          }
        }
      }
    }

    .selectable-tag-wrapper.dark-mode.selected {
      background-color: ${REBRAND_HOVER_BACKGROUND_COLOR};

      .selectable-tag-image-container {
        background-color: currentColor;

        .selectable-tag-image {
          path {
            fill: ${REBRAND_HOVER_BACKGROUND_COLOR};
          }
        }
      }
    }
  }
`;
